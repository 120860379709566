'use client';

import { FC } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import Grid from 'antd/lib/grid';

import ShowElement from 'components/atoms/ShowElement';
import { IHomePageProps } from './types';
import {
  Routes,
  scrollableTargetForSearchDropDown
} from 'common/constants/common';
import MainSearchBar from 'components/organisms/MainSearchBar';
import ApplicationCardsSkeleton from 'components/molecules/ApplicationCardsSkeleton';
import FilterCategory from 'components/organisms/FilterCategory';
import InfiniteScroll from 'components/organisms/InfiniteScroll';
import ApplicationsList from 'components/organisms/ApplicationsList';
import SectionTitle from 'components/molecules/SectionTitle';
import CategoriesPopup from 'components/organisms/CategoriesPopup';
import useCategoriesQuery from 'hooks/apis/queries/useCategoriesQuery';
import useApplicationsInfiniteQuery from 'hooks/apis/queries/useApplicationsInfiniteQuery';
import useSearchApplicationsInfiniteQuery from 'hooks/apis/queries/useSearchApplicationsInfiniteQuery';
import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import useTranslations from 'hooks/common/useTranslations';
import { defaultFetchedCategory } from './constants';
import { OnChangeHandlerType } from 'common/globalTypes/utilityTypes/common';
import {
  HomeStyleWrapper,
  NavStyleContainer,
  SearchStyleContainer,
  AppsListContainer,
  TitleSectionContainer
} from './styles';

const { useBreakpoint } = Grid;

const HomePage: FC<IHomePageProps> = ({
  applicationsForServerSide,
  categoriesFromServerSide
}) => {
  const { t } = useTranslations();
  const { md } = useBreakpoint();
  const { push } = useRouteWithLanguage();

  const { data: categories, getTitleByAlias } = useCategoriesQuery(
    categoriesFromServerSide
  );

  const {
    data: applications,
    fetchNextPage: fetchNextPageApps,
    isFetchingNextPage: isFetchingNextPageApps,
    hasNextPage: hasNextPageApps
  } = useApplicationsInfiniteQuery(
    applicationsForServerSide,
    defaultFetchedCategory
  );

  const {
    foundAppsForDropDownList,
    fetchNextPage: fetchNextPageFoundApps,
    isFetching: isFetchingFoundApps,
    hasNextPage: hasNextPageFoundApps,
    onSearchTermHandler
  } = useSearchApplicationsInfiniteQuery();

  const titleByCategory = getTitleByAlias(defaultFetchedCategory);

  const onSearchHandler = (event: OnChangeHandlerType) => {
    const search = event.target.value;
    push(`${Routes.SEARCH.pathname}?query=${search}`);
  };

  const onSearchClick = () => {
    return push(Routes.SEARCH.pathname);
  };

  const handleSelectApp = (event: MenuInfo) => {
    push(`${event?.key}`);
  };

  return (
    <HomeStyleWrapper>
      <SearchStyleContainer>
        <InfiniteScroll
          next={fetchNextPageFoundApps}
          hasMore={Boolean(hasNextPageFoundApps)}
          scrollTarget={`.${scrollableTargetForSearchDropDown}`}
          dataLength={foundAppsForDropDownList.length}
        >
          <MainSearchBar
            isFromMainPage={true}
            onSelectApp={handleSelectApp}
            onSearchClick={onSearchClick}
            onChangeSearch={onSearchTermHandler}
            foundApps={foundAppsForDropDownList}
            searchLoading={Boolean(isFetchingFoundApps)}
            onSearchPressEnterHandler={onSearchHandler}
          />
        </InfiniteScroll>
      </SearchStyleContainer>

      <NavStyleContainer role="navigation">
        <FilterCategory menuItems={categories} title={t('common.category')} />
      </NavStyleContainer>
      <AppsListContainer>
        <TitleSectionContainer>
          <SectionTitle
            titleLevel={2}
            title={titleByCategory}
            subTitle={t('common.mostPopularAppForSection')}
          />
          <ShowElement isShow={!md}>
            <CategoriesPopup categories={categories} />
          </ShowElement>
        </TitleSectionContainer>
        <InfiniteScroll
          next={fetchNextPageApps}
          loading={isFetchingNextPageApps}
          hasMore={Boolean(hasNextPageApps)}
          loader={<ApplicationCardsSkeleton />}
        >
          <ApplicationsList applications={applications} />
        </InfiniteScroll>
      </AppsListContainer>
    </HomeStyleWrapper>
  );
};

export default HomePage;
